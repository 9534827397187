import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Button,
  Divider,
  useNotification
} from '@ubisend/pulse-components';
import {
  useConditionalReducer,
  Conditionals,
  AddConditionalButton,
  ConditionalContext,
  Match
} from '@ubisend/pulse-conditionals';

import Modal from '../../../Modal';
import { conditionals } from '../../../../../../subjects/index';
import { useBuilder } from '../../../../hooks/index';
import { updateStepTrigger } from '../../../../api/index';
import StepPreview from '../MessageStep/StepPreview';
import { timeConditionals, formatTimeValue } from './AddTriggerNode'

const EditTriggerNode = ({ handleClose, node }) => {
  const { dispatch } = useBuilder();
  const conditional = useConditionalReducer({
    subjects: conditionals,
    conditionals: {
      conditionals: node.base.conditionals,
      match: node.base.match
    }
  });
  const { showSuccess } = useNotification();

  const { isLoading, mutate } = useMutation(updateStepTrigger, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully updated trigger');
      dispatch({
        type: 'UPDATE_TRIGGER',
        stepId: node.meta.stepId,
        triggerId: node.id,
        triggerDetails: data.data
      });
      handleClose();
    }
  });

  const handleSubmit = event => {
    event.preventDefault();

    if (conditional.valid && !isLoading) {
      if (
        timeConditionals.includes(conditional.form.conditionals[0].type) &&
        conditional.form.conditionals.length === 1
      ) {
        const formattedTime = formatTimeValue(conditional.form);

        mutate({
          stepId: node.meta.stepId,
          triggerId: node.id,
          trigger: {
            description: `Trigger step #${node.meta.stepId}`,
            ...formattedTime,
            variables: node.base.variables.map(variable => variable.id),
            metrics: node.base.metrics.map(metric => metric.id)
          }
        });
      } else {
        mutate({
          stepId: node.meta.stepId,
          triggerId: node.id,
          trigger: {
            description: `Trigger step #${node.meta.stepId}`,
            ...conditional.form,
            variables: node.base.variables.map(variable => variable.id),
            metrics: node.base.metrics.map(metric => metric.id)
          }
        });
      }
    }
  };

  const destinationNode = {
    id: node.id,
    blocks: [],
    base: node.base.entry_step
  };

  return (
    <Modal
      header="Edit trigger"
      handleClose={handleClose}
      style={{ maxWidth: '100%', width: '80rem' }}>
      <Flex>
        <Flex pad fat col borderRight>
          <Flex as="form" col tall onSubmit={handleSubmit}>
            <ConditionalContext.Provider value={conditional}>
              <Match />
              <Divider />
              <Conditionals labelPrefix="trigger-conditionals" />
              <Flex right>
                <AddConditionalButton />
              </Flex>
              <Divider />
              <Flex center xSpace bottom>
                <Flex xSpace fat right bottom>
                  <Button
                    variant="primary"
                    disabled={!conditional.valid || isLoading}
                    type="submit">
                    Save
                  </Button>
                </Flex>
              </Flex>
            </ConditionalContext.Provider>
          </Flex>
        </Flex>
        <Flex pad ySpace col center style={{ background: '#f3f3f4' }}>
          <StepPreview node={destinationNode} />
        </Flex>
      </Flex>
    </Modal>
  );
};

EditTriggerNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    meta: PropTypes.shape({
      stepId: PropTypes.number.isRequired
    }).isRequired,
    base: PropTypes.shape({
      match: PropTypes.string.isRequired,
      conditionals: PropTypes.array.isRequired,
      entry_step: PropTypes.object.isRequired,
      variables: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired
    })
  }).isRequired
};

export default EditTriggerNode;
