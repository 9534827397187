/**
 * Is an auto generated error message, providing
 * key value pairs of invalid form fields.
 */
const isAutoError = data => Boolean(data?.errors);

const getReadableError = errors => {
  return errors
    .map(error => {
      return error;
    })
    .join('\n\n');
};

const getAutoError = data => {
  const errors = Object.values(data.errors).map(getReadableError).join('\n\n');

  return errors;
};

/**
 * Is a custom error with a single `message` field.
 */
const isCustomError = data => Boolean(data?.message);

const getCustomError = data => data.message;

const getUnauthorisedMessage = ({ config }) => {
  if (config.url.includes('/login')) {
    return 'Invalid email or password.';
  }

  return 'Your login session has expired. Please login again.';
};

const getForbiddenMessage = ({ data }) => {
  if (isCustomError(data)) {
    return getCustomError(data);
  }

  return `You are not authorised to perform that action.`;
};

const getNotFoundMessage = ({ data }) => {
  if (isCustomError(data)) {
    return getCustomError(data);
  }

  return 'That resource could not be found.';
};

const getUnprocessableEntityMessage = ({ data }) => {
  if (isAutoError(data)) {
    return getAutoError(data);
  }

  if (isCustomError(data)) {
    return getCustomError(data);
  }

  return 'There was a problem with the form submission.';
};

const getInternalServerMessage = () => {
  return 'Sorry, something went wrong. Please contact your account manager if the problem persists.';
};

const getDefaultMessage = () => {
  return 'Sorry, something went wrong. Please contact your account manager if the problem persists.';
};

const codes = {
  401: getUnauthorisedMessage,
  403: getForbiddenMessage,
  404: getNotFoundMessage,
  422: getUnprocessableEntityMessage,
  500: getInternalServerMessage
};

const getApiErrorMessage = ({ response }) => {
  // Check if the response status is 409 and directly use the backend's message if available
  if (response.status === 409) {
    return response.data.error;
  }

  // Fallback to defined error handlers or the default message
  const getMessage = codes[response.status] || getDefaultMessage;

  return getMessage(response);
};

export default getApiErrorMessage;
