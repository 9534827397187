import { hasPermission } from '@ubisend/pulse-auth';

import { GenerativeAi } from './Pages/index';

const routes = [
  {
    path: '/generative-ai',
    component: GenerativeAi,
    name: 'Generative AI',
    canAccess: hasPermission('view generative ai')
  }
];

export default routes;
