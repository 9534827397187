import React, { useState } from 'react';

import {
  Button,
  Box,
  Flex,
  Paragraph,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableActions,
  LoadingContainer,
  useNotification,
  OrderableTableRow,
  useOrderableTableReducer,
  usePaginationReducer,
  Pagination
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useQuery, useQueryClient } from '@ubisend/pulse-hooks';
import { FileSelect, types } from '@ubisend/pulse-files';

import { createResource, deleteResource } from '../api/index';

const Resource = () => {
  const { showSuccess, showError } = useNotification();
  const [upload, setUpload] = useState(false);

  const showUpload = () => setUpload(true);
  const hideUpload = () => setUpload(false);

  // Ordering and pagination reducers
  const order = useOrderableTableReducer({ id: 'resources' });
  const pagination = usePaginationReducer({ id: 'resources' });

  // Query Client
  const queryClient = useQueryClient();

  // Fetch resources with ordering and pagination
  const { data: resources, isLoading, error } = useQuery([
    'generative-ai/resource',
    { ...pagination.params, ...order.params }
  ]);

  // Refetch resources manually after resource creation or deletion
  const refetchResources = () => {
    queryClient.invalidateQueries('generative-ai/resource');
  };

  // Create a new resource
  const handleNewResource = async file => {
    try {
      await createResource(file);
      refetchResources(); // Refetch resources after adding new resource
      showSuccess('Successfully added a new resource');
      hideUpload();
    } catch (error) {
      showError('Error adding new resource: ' + error.message);
    }
  };

  // Delete a resource
  const handleResourceDelete = async resourceToDelete => {
    try {
      const data = await deleteResource(resourceToDelete.id);
      if (data.status === 202) {
        showSuccess(`Successfully deleted "${resourceToDelete.name}".`);
        refetchResources();
      } else {
        showError(
          `Failed to delete resource "${resourceToDelete.name}". Unexpected status: ${data.status}`
        );
      }
    } catch (error) {
      showError(`Error deleting resource: ${error.message}`);
    }
  };

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (error) {
    return <p>Error fetching resources: {error.message}</p>;
  }

  const meta = resources?.meta ?? {}; // Check if meta is at the root level

  return (
    <Flex col>
      <PermissionFilter can="view generative ai">
        {resources.data.length === 0 && (
          <Box border="dashed">
            <Flex col middle center>
              <Paragraph mb secondary>
                No documents have been added yet:
              </Paragraph>
              <Button icon="plus" variant="primary" onClick={showUpload}>
                Add Document
              </Button>
            </Flex>
          </Box>
        )}
        {upload && (
          <FileSelect
            types={[types.pdf, types.document]}
            handleFileSelect={handleNewResource}
            handleCancel={hideUpload}
          />
        )}
        {resources.data.length > 0 && (
          <>
            <Flex border xScroll>
              <Table loading={isLoading}>
                <TableHead>
                  <OrderableTableRow
                    rows={[
                      { label: 'Name', sort: 'name' },
                      { label: 'Type', sort: 'type' },
                      null
                    ]}
                    {...order.props}
                  />
                </TableHead>
                <TableBody>
                  {resources.data.map((resource, key) => (
                    <TableRow key={key}>
                      <TableCell>{resource.name}</TableCell>
                      <TableCell>{resource.type}</TableCell>
                      <TableActions>
                        <PermissionFilter can="edit generative ai">
                          <Button
                            variant="secondary"
                            icon="trash"
                            colour="danger"
                            onClick={() => handleResourceDelete(resource)}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Flex>
            {meta && (
              <Pagination
                pagination={meta} // Pass pagination data safely
                {...pagination.props}
              />
            )}
            <Button
              mt
              mlAuto
              icon="plus"
              variant="primary"
              onClick={showUpload}>
              Add Document
            </Button>
          </>
        )}
      </PermissionFilter>
    </Flex>
  );
};

export default Resource;
