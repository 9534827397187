import React from 'react';
import PropTypes from 'prop-types';

import { Label, Paragraph } from '@ubisend/pulse-components';

const truncateUrl = (url, maxLength = 70) => {
  if (url.length <= maxLength) {
    return url;
  }
  return url.slice(0, maxLength) + '...';
};

const LogDescription = ({ description }) => {
  // Regular expression to match URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Replace URLs in the description with anchor tags
  const processedDescription = description.replace(urlRegex, url => {
    const truncatedUrl = truncateUrl(url);
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${truncatedUrl}</a>`;
  });

  return (
    <div>
      <Label>Description</Label>
      {/* Use dangerouslySetInnerHTML to render the processed description */}
      <Paragraph dangerouslySetInnerHTML={{ __html: processedDescription }} />
    </div>
  );
};

LogDescription.propTypes = {
  description: PropTypes.string.isRequired
};

export default LogDescription;
