import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select, Flex } from '@ubisend/pulse-components';

const format = ({ id, name }) => {
  return { label: name, value: id };
};

const CustomVariableSelect = ({
  value,
  isMulti = false,
  currentlySelected,
  ...rest
}) => {
  const { data, isLoading, isSuccess } = useQuery('variables/all');

  const getSingleValue = () => {
    if (!value) {
      return null;
    }

    return format(data.data.find(variable => variable.id === value));
  };

  const getMultiValue = () => {
    return value.map(item => {
      return format(data.data.find(variable => variable.id === item));
    });
  };

  const getValue = () => {
    if (isMulti) {
      return getMultiValue();
    }

    return getSingleValue();
  };

  const availableOptions = () => {
    return data.data.filter(
      item => !currentlySelected?.some(variable => variable.base.id === item.id) // eslint-disable-line
    );
  };

  return (
    <Flex fat>
      <Select
        placeholder="variable-name"
        isLoading={isLoading}
        value={isSuccess ? getValue() : null}
        options={isSuccess ? availableOptions().map(format) : []}
        isMulti={isMulti}
        {...rest}
      />
    </Flex>
  );
};

CustomVariableSelect.propTypes = {
  value: PropTypes.number,
  isMulti: PropTypes.bool,
  currentlySelected: PropTypes.object
};

export default CustomVariableSelect;
