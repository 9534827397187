import React from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Divider,
  ActionMenu,
  useModal,
  useNotification,
  Button
} from '@ubisend/pulse-components';

import { exportMetric } from '../api/index';
import { exportableMetricKeys as nonExplorableMetrics } from './DragableMetric';

const ActionContainer = styled(Flex)`
  ${tw`absolute top-0 right-0 z-10 mt-2`}
`;

const MetricMenu = ({ metric, params }) => {
  const { showModal, hideModal } = useModal();
  const { showSuccess } = useNotification();

  const { start, end, compare } = params;
  const mutation = useMutation(
    exportMetric,
    {
      id: metric.id,
      start,
      end,
      compare,
      raw: true
    }
  );

  const handleExport = (raw = true) => {
    showModal({
      header: 'Export Metric',
      body: `Do you want to export "${metric.name}" in ${raw ? 'raw' : 'clean'} format?`,
      handleConfirm: async () => {
        await mutation.mutateAsync({ id: metric.id, raw: raw, ...params });
        showSuccess(
          `Successfully exported "${metric.name}" in ${raw ? 'raw' : 'clean'} format. Keep an eye on your emails.`
        );
        hideModal();
      }
    });
  };

  return (
    <ActionContainer>
      <ActionMenu
        position={ActionMenu.POSITIONS.LEFT}
        buttonProps={{ 'aria-label': `metric-menu-${metric.name}` }}>
        <Flex col>
          {
            !nonExplorableMetrics.includes(metric.key)
            && (
              <>
                <Button
                  variant="inline"
                  icon="externalLink"
                  as={Link}
                  to={{
                    pathname: `dashboard/${metric.id}`,
                    state: { start, end }
                  }}>
                  View more
                </Button>
                <Divider mtNone mbNone />
              </>
            )
          }
          <Button
            variant="inline"
            onClick={() => handleExport()}
            icon="download">
            Export Raw
          </Button>
          {
            !nonExplorableMetrics.includes(metric.key)
            && (
              <>
                <Divider mtNone mbNone />
                <Button
                  variant="inline"
                  onClick={() => handleExport(false)}
                  icon="download">
                  Export Clean
                </Button>
              </>
            )
          }
        </Flex>
      </ActionMenu>
    </ActionContainer>
  );
};

MetricMenu.propTypes = {
  hasRecordedMetrics: PropTypes.bool.isRequired,
  metric: PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  params: PropTypes.object.isRequired
};

export default MetricMenu;
