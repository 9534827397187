import axios from 'axios';

const updateGenerativeDriver = id => axios.put(`/generative-ai`, { id });

const createResource = params => {
  return axios.post('/generative-ai/resource', params);
};

const deleteResource = id => {
  return axios.delete(`generative-ai/resource/${id}`);
};

const fetchPrompt = () => {
  return axios.get(`generative-ai/prompt`);
};

const updatePrompt = params => {
  return axios.post(`generative-ai/prompt`, params);
};

const createSitemap = params => {
  return axios.post('/generative-ai/sitemap', params);
};

const deleteSitemap = id => {
  return axios.delete(`generative-ai/sitemap/${id}`);
};

const recrawlSitemapUrl = id => {
  return axios.post(`generative-ai/sitemap/recrawl/${id}`);
};

const fetchlSitemapUrl = id => {
  return axios.post(`generative-ai/sitemap/url/${id}`);
};

export {
  createResource,
  deleteResource,
  updateGenerativeDriver,
  updatePrompt,
  fetchPrompt,
  createSitemap,
  deleteSitemap,
  recrawlSitemapUrl,
  fetchlSitemapUrl
};
