import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Button,
  Divider,
  useNotification
} from '@ubisend/pulse-components';
import {
  useConditionalReducer,
  Conditionals,
  AddConditionalButton,
  ConditionalContext,
  Match
} from '@ubisend/pulse-conditionals';

import Modal from '../../../Modal';
import { conditionals } from '../../../../../../subjects/index';
import { useBuilder } from '../../../../hooks/index';
import { createStepTrigger } from '../../../../api/index';
import StepPreview from '../MessageStep/StepPreview';

const timeConditionals = [
  'Subscriber returns before date and time',
  'Subscriber returns after date and time',
  'Subscriber returns before time',
  'Subscriber returns after time'
];

const formatTimeValue = (form) => {
  const conditionalData = form;

  const { condition } = conditionalData.conditionals[0];

  let formattedData;

  if (condition[2] === ':') {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = String(today.getMonth() + 1).padStart(2, '0');
    const currentDay = String(today.getDate()).padStart(2, '0');

    const conditionWithDate = `${currentYear}-${currentMonth}-${currentDay} ${condition}`;
    const utcDate = new Date(conditionWithDate).toISOString();

    // manually extract the relevant times
    const hours = utcDate.slice(11, 13);
    const minutes = utcDate.slice(14, 16);
    const seconds = utcDate.slice(17, 19);

    formattedData = `${hours}:${minutes}:${seconds}`;
  } else {
    const utcDate = new Date(condition).toISOString();

    // manually extract the relevant times
    const year = utcDate.slice(0, 4);
    const month = utcDate.slice(5, 7);
    const day = utcDate.slice(8, 10);
    const hours = utcDate.slice(11, 13);
    const minutes = utcDate.slice(14, 16);
    const seconds = utcDate.slice(17, 19);

    formattedData = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  return {
    ...conditionalData,
    conditionals: [
      {
        ...conditionalData.conditionals[0],
        condition: formattedData
      }
    ]
  };
}

const AddTriggerNode = ({ handleClose, node }) => {
  const { dispatch } = useBuilder();
  const conditional = useConditionalReducer({
    subjects: conditionals,
    conditionals: null,
    messageEquals: true
  });
  const { showSuccess } = useNotification();

  const { isLoading, mutate } = useMutation(createStepTrigger, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully added trigger');
      dispatch({
        type: 'ADD_TRIGGER',
        stepId: node.id,
        triggerDetails: data.data
      });
      handleClose();
    }
  });

  const handleSubmit = event => {
    event.preventDefault();

    if (conditional.valid && !isLoading) {
      if (
        timeConditionals.includes(conditional.form.conditionals[0].type) &&
        conditional.form.conditionals.length === 1
      ) {
        const formattedTime = formatTimeValue(conditional.form);

        mutate({
          stepId: node.id,
          trigger: {
            description: `Trigger for step #${node.id}`,
            ...formattedTime,
            x: node.x + 300,
            y: node.y - 110,
            variables: [],
            metrics: []
          }
        });
      } else {
        mutate({
          stepId: node.id,
          trigger: {
            description: `Trigger for step #${node.id}`,
            ...conditional.form,
            x: node.x + 300,
            y: node.y - 110,
            variables: [],
            metrics: []
          }
        });
      }

    }
  };

  const destinationNode = {
    id: node.id,
    blocks: [],
    base: node.base
  };

  return (
    <Modal
      header="Add trigger"
      handleClose={handleClose}
      style={{ maxWidth: '100%', width: '80rem' }}>
      <Flex>
        <Flex pad fat col borderRight>
          <Flex as="form" col tall onSubmit={handleSubmit}>
            <ConditionalContext.Provider value={conditional}>
              <Match />
              <Divider />
              <Conditionals labelPrefix="trigger-conditionals" />
              <Flex right>
                <AddConditionalButton />
              </Flex>
              <Divider />
              <Flex center xSpace bottom>
                <Flex xSpace fat right bottom>
                  <Button
                    variant="primary"
                    disabled={!conditional.valid || isLoading}
                    type="submit">
                    Save
                  </Button>
                </Flex>
              </Flex>
            </ConditionalContext.Provider>
          </Flex>
        </Flex>
        <Flex pad ySpace col center style={{ background: '#f3f3f4' }}>
          <StepPreview node={destinationNode} />
        </Flex>
      </Flex>
    </Modal>
  );
};

AddTriggerNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    base: PropTypes.object.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }).isRequired
};

export default AddTriggerNode;

export { timeConditionals, formatTimeValue };
