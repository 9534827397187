import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import { Tag } from '../Tag';
import { Paragraph } from '../Text';

const Container = styled.div`
  ${tw`p-8 pb-16 md:p-10 flex flex-col flex-grow items-center relative`}
`;

const HeaderContainer = styled.div`
  ${tw`mb-6 flex items-center`}
  ${props => props.center && tw`justify-center`}
`;

const TextContainer = styled.div`
  ${props => props.half && `width: 50%;`}
  & > h1 {
    ${tw`mt-0 text-2xl`}
  }
`;

const ActionsContainer = styled.div`
  ${tw`h-full flex items-center`}
`;

const PrevActionsContainer = styled.div`
  display: flex;
  font-size: 14px;
  width: 100%;
`;

const Divider = styled.div`
  ${tw`flex flex-1`}
`;

const InnerContainer = styled.div`
  ${tw`w-full flex flex-col flex-grow`}
  ${props => !props.wide && tw`max-w-5xl`}
`;

const PageWrapper = ({
  header,
  subheader,
  actions,
  help,
  children,
  prevAction,
  wide = false,
  center = false,
  half = false,
  beta = false
}) => (
  <Container>
    <InnerContainer half={half} wide={wide}>
      <HeaderContainer center={center}>
        <TextContainer half={half}>
          {prevAction && (
            <PrevActionsContainer>{prevAction}</PrevActionsContainer>
          )}
          {header && (
            <h1>
              {header}
              {beta && (
                <Tag mlSm background="warning" colour="warning">
                  Beta
                </Tag>
              )}
            </h1>
          )}
          {subheader && <Paragraph secondary>{subheader}</Paragraph>}
          {help && help}
        </TextContainer>
        {actions && (
          <>
            <Divider />
            <ActionsContainer>{actions}</ActionsContainer>
          </>
        )}
      </HeaderContainer>
      {children}
    </InnerContainer>
  </Container>
);

PageWrapper.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subheader: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actions: PropTypes.element,
  help: PropTypes.element,
  wide: PropTypes.bool,
  center: PropTypes.bool,
  half: PropTypes.bool,
  prevAction: PropTypes.element,
  beta: PropTypes.bool
};

export default PageWrapper;
