import React from 'react';

import {
  Explainer,
  Flex,
  OneHalfLayout,
  PageWrapper,
  Panel,
  Placeholder
} from '@ubisend/pulse-components';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PolicyAccept, BackButton } from '@ubisend/pulse-channels';

import { acceptPolicy as acceptPolicyApi } from '../api/index';
import RcsDataForm from '../Components/RcsDataForm';

const RcsLink = () => {
  const query = useQuery('rcs/settings');
  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('rcs/settings');
    }
  });

  const PolicyCheck = () => {
    if (!query.data.data.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://sopranodesign.com/privacy/"
        />
      );
    }

    return (
      <Panel divider header="Link your RCS">
        <Flex col>
          <Explainer mb>
            Please fill out the fields below in line with Soprano Design RCS
            Provider API.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              // TODO: change to new doc link
              href="/docs/2151317744/2410545153">
              Learn more
            </a>
            .
          </Explainer>
          <RcsDataForm
            agentId={query.data.data.agent_id}
            token={query.data.data.api_id}
            prevBaseUri={query.data.data.base_uri}
            noSecret={query.data.data.has_not_api_key}
          />
        </Flex>
      </Panel>
    );
  };

  return (
    <PageWrapper
      center
      half
      header="RCS"
      prevAction={<BackButton to="/channels" />}>
      <Flex col center>
        <OneHalfLayout>
          {query.isLoading && <Placeholder items={1} subitems={2} />}
          {query.isSuccess && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default RcsLink;
