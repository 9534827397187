import React from 'react';
import tw, { styled } from 'twin.macro';

import OneHalfLayout from './OneHalfLayout';

const StyledLeftHalfLayout = styled(OneHalfLayout)`
  ${tw`w-full max-w-lg `}
`;

const Spacer = styled.div`
  ${tw`mr-4`}
`;

const LeftHalfLayoutResponsive = ({ children }) => (
  <StyledLeftHalfLayout>
    <Spacer>{children}</Spacer>
  </StyledLeftHalfLayout>
);

export default LeftHalfLayoutResponsive;
