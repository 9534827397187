import React from 'react';
import PropTypes from 'prop-types';

import {
  Flex,
  Button,
  ModalPortal,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
  TextInput,
  Select
} from '@ubisend/pulse-components'; // Adjust this import based on your component library

const SitemapModal = ({
  handleClose,
  handleSave,
  sitemapUrl,
  setSitemapUrl,
  selectedOption,
  setSelectedOption,
  options
}) => {
  return (
    <ModalPortal>
      <ModalContainer>
        <ModalContent>
          <ModalHeader>Add URLs</ModalHeader>
          <ModalBody>
            <Flex fat col>
              <Label htmlFor="sitemapType">Type</Label>
              <Select
                id="sitemapType"
                value={selectedOption}
                onChange={e => setSelectedOption(e)}
                options={options}
              />
            </Flex>
            <br />
            <Flex fat col>
              <Label htmlFor="sitemapUrl">URL</Label>
              <TextInput
                id="sitemapUrl"
                value={sitemapUrl}
                onChange={e => setSitemapUrl(e.target.value)}
                placeholder="Enter URL"
              />
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex right>
              <Flex xSpace>
                <Button variant="secondary" onClick={handleClose}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                  Save
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </ModalContainer>
    </ModalPortal>
  );
};

SitemapModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  sitemapUrl: PropTypes.string.isRequired,
  setSitemapUrl: PropTypes.func.isRequired,
  selectedOption: PropTypes.object,
  setSelectedOption: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
};

export default SitemapModal;
