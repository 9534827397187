import React from 'react';
import PropTypes from 'prop-types';

import { useMutation } from '@ubisend/pulse-hooks';
import {
  Flex,
  Button,
  Divider,
  useNotification
} from '@ubisend/pulse-components';
import {
  useConditionalReducer,
  Conditionals,
  AddConditionalButton,
  ConditionalContext,
  Match
} from '@ubisend/pulse-conditionals';

import Modal from '../../../Modal';
import { conditionals } from '../../../../../../subjects/index';
import { useBuilder, useStep } from '../../../../hooks/index';
import { updateStepTransition } from '../../../../api/index';
import TransitionPreview from './TransitionPreview';
import { timeConditionals, formatTimeValue } from '../Trigger/AddTriggerNode'


const EditTransitionNode = ({ handleClose, node }) => {
  const { dispatch } = useBuilder();
  const conditional = useConditionalReducer({
    subjects: conditionals,
    conditionals: {
      conditionals: node.base.conditionals,
      match: node.base.match
    }
  });
  const { showSuccess } = useNotification();
  const start = useStep(node.base.start.id);
  const destination = useStep(node.base.destination.id);

  const { isLoading, mutate } = useMutation(updateStepTransition, {
    onSuccess: ({ data }) => {
      showSuccess('Successfully updated transition');
      dispatch({
        type: 'UPDATE_TRANSITION',
        stepId: node.meta.stepId,
        transitionId: node.id,
        transitionDetails: data.data
      });
      handleClose();
    }
  });

  const handleSubmit = event => {
    event.preventDefault();

    if (conditional.valid && !isLoading) {
      if (
        timeConditionals.includes(conditional.form.conditionals[0].type) &&
        conditional.form.conditionals.length === 1
      ) {
        const formattedTime = formatTimeValue(conditional.form);

        mutate({
          stepId: node.meta.stepId,
          transitionId: node.id,
          transition: {
            description: `Transition to step #${start.id}`,
            transition: {
              id: destination.id,
              ...formattedTime,
              variables: node.base.variables.map(variable => variable.id),
              metrics: node.base.metrics.map(metric => metric.id)
            }
          }
        });
      } else {
        mutate({
          stepId: node.meta.stepId,
          transitionId: node.id,
          transition: {
            description: `Transition to step #${start.id}`,
            transition: {
              id: destination.id,
              ...conditional.form,
              variables: node.base.variables.map(variable => variable.id),
              metrics: node.base.metrics.map(metric => metric.id)
            }
          }
        });
      }
    }
  };

  const startNode = {
    id: start.id,
    blocks: [],
    base: start
  };

  const destinationNode = {
    id: destination.id,
    blocks: [],
    base: destination
  };

  return (
    <Modal
      header="Edit transition"
      handleClose={handleClose}
      style={{ maxWidth: '100%', width: '80rem' }}>
      <Flex>
        <Flex pad fat col borderRight>
          <Flex as="form" col tall onSubmit={handleSubmit}>
            <ConditionalContext.Provider value={conditional}>
              <Flex grow col>
                <Match />
                <Divider fullWidth />
                <Conditionals labelPrefix="transition-conditionals" />
                <Flex right>
                  <AddConditionalButton />
                </Flex>
              </Flex>
              <Divider fullWidth />
              <Flex center xSpace bottom>
                <Flex xSpace fat right bottom>
                  <Button
                    variant="primary"
                    disabled={!conditional.valid || isLoading}
                    type="submit">
                    Save
                  </Button>
                </Flex>
              </Flex>
            </ConditionalContext.Provider>
          </Flex>
        </Flex>
        <TransitionPreview
          startNode={startNode}
          destinationNode={destinationNode}
        />
      </Flex>
    </Modal>
  );
};

EditTransitionNode.propTypes = {
  handleClose: PropTypes.func.isRequired,
  node: PropTypes.shape({
    id: PropTypes.number.isRequired,
    meta: PropTypes.shape({
      stepId: PropTypes.number.isRequired
    }).isRequired,
    base: PropTypes.shape({
      match: PropTypes.string.isRequired,
      conditionals: PropTypes.array.isRequired,
      variables: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired,
      metrics: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired
      ).isRequired,
      start: PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired,
      destination: PropTypes.shape({
        id: PropTypes.number.isRequired
      }).isRequired
    })
  }).isRequired
};

export default EditTransitionNode;
