import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextInput,
  FormGroup,
  Label,
  Divider,
  Flex,
  FormHeading,
  Select
} from '@ubisend/pulse-components';
import {
  useConditional,
  Conditionals,
  Match,
  AddConditionalButton
} from '@ubisend/pulse-conditionals';
import { GroupSelect } from '@ubisend/pulse-groups';

import { formatTimeValue, timeConditionals } from '../Pages/Builder/Components/Nodes/Types/Trigger/AddTriggerNode';

const ConversationDetails = ({
  handleSubmit,
  initialTitle = '',
  initialDescription = '',
  initialStartingStepType = { label: 'Message', value: 'messageStep' },
  initialGroups = [],
  showConditional = true,
  loading = false
}) => {
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);
  const [groups, setGroups] = useState(initialGroups);
  const [startingStepType, setStartingStepType] = useState(
    initialStartingStepType
  );
  const conditional = useConditional();

  const handleTitleChange = event => setTitle(event.target.value);

  const handleDescriptionChange = event => setDescription(event.target.value);

  const handleGroupsChange = groups => {
    setGroups(groups ? groups.map(group => group.value) : []);
  };

  const handleStartingStepTypeChange = event => {
    setStartingStepType(event);
  };

  const startStepTypeOptions = [
    { label: 'Message', value: 'messageStep' },
    { label: 'Automated', value: 'automatedStep' }
  ];

  const valid = showConditional
    ? title.length > 3 && description.length > 3 && conditional.valid
    : title.length > 3 && description.length > 3;

  const onSubmit = event => {
    event.preventDefault();

    if (!valid || loading) {
      return;
    }

    if (
      timeConditionals.includes(conditional.form.conditionals[0].type) &&
      conditional.form.conditionals.length === 1
    ) {
      const formattedTime = formatTimeValue(conditional.form);

      handleSubmit({
        title,
        description,
        groups,
        ...(showConditional && {
          ...formattedTime,
          startingStepType: startingStepType.value,
          metrics: [],
          variables: []
        })
      });
    } else {
      handleSubmit({
        title,
        description,
        groups,
        ...(showConditional && {
          ...conditional.form,
          startingStepType: startingStepType.value,
          metrics: [],
          variables: []
        })
      });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="name">Name *</Label>
        <TextInput
          id="name"
          value={title}
          onChange={handleTitleChange}
          placeholder="My new workflow"
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="description">Description *</Label>
        <TextInput
          id="description"
          value={description}
          onChange={handleDescriptionChange}
          placeholder="This workflow..."
        />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="tags">Tags</Label>
        <GroupSelect
          aria-label="Workflow tags"
          id="tags"
          for="conversations"
          value={groups}
          onChange={handleGroupsChange}
        />
      </FormGroup>
      {showConditional && (
        <FormGroup>
          <Label htmlFor="tags">Starting step</Label>
          <Select
            aria-label="Workflow starting step"
            id="starting_step"
            placeholder={'Select step'}
            value={startingStepType}
            options={startStepTypeOptions}
            onChange={handleStartingStepTypeChange}
          />
        </FormGroup>
      )}
      {showConditional && (
        <>
          <FormHeading>Trigger Workflow</FormHeading>
          <Match />
          <Divider />
          <Conditionals />
        </>
      )}
      <Flex right xSpace>
        {showConditional && <AddConditionalButton />}
      </Flex>
      {showConditional && (
        <>
          <Divider />
          <Flex center xSpace bottom>
            <Flex xSpace fat right bottom>
              <Button
                variant="primary"
                disabled={!valid || loading}
                type="submit">
                Save
              </Button>
            </Flex>
          </Flex>
        </>
      )}
      {!showConditional && (
        <Flex right>
          <Button variant="primary" disabled={!valid || loading} type="submit">
            Save
          </Button>
        </Flex>
      )}
    </form>
  );
};

ConversationDetails.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialTitle: PropTypes.string,
  initialDescription: PropTypes.string,
  initialGroups: PropTypes.arrayOf(PropTypes.number),
  initialStartingStepType: PropTypes.object,
  showConditional: PropTypes.bool,
  loading: PropTypes.bool
};

export default ConversationDetails;
