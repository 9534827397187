import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Flex,
  Label,
  TextInput,
  useNotification,
  Span
} from '@ubisend/pulse-components';
import { useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import { updateSettings as updateSettingsApi } from '../api/index';

const RcsDataForm = ({
  agentId: initialAgentId,
  token,
  prevBaseUri,
  noSecret
}) => {
  const [agentId, setAgentId] = useState(initialAgentId ?? '');
  const [apiId, setApiId] = useState(token ?? '');
  const [apiKey, setApiKey] = useState('');
  const [baseUri, setBaseUri] = useState(prevBaseUri ?? '');
  const [showApiKey, setShowApiKey] = useState(noSecret);

  const { showSuccess } = useNotification();
  const queryClient = useQueryClient();

  const { mutate: updateSettings } = useMutation(updateSettingsApi, {
    onSuccess: () => {
      showSuccess('Successfully updated rcs settings');
      queryClient.invalidateQueries('rcs/settings');
    }
  });

  const handleAgentIdChange = event => {
    setAgentId(event.target.value);
  };

  const handleBaseUriChange = event => {
    setBaseUri(event.target.value);
  };

  const handleApiIdChange = event => {
    setApiId(event.target.value);
  };

  const handleApiKeyChange = event => {
    setApiKey(event.target.value);
  };

  const handleSubmit = () => {
    if (showApiKey) {
      updateSettings({
        agent_id: agentId,
        api_id: apiId,
        api_key: apiKey,
        base_uri: baseUri
      });
    } else {
      updateSettings({
        agent_id: agentId,
        api_id: apiId,
        base_uri: baseUri
      });
    }
  };

  const handleShowClientSecret = () => setShowApiKey(true);

  return (
    <Flex col>
      <Flex col mb>
        <Label htmlFor="rcs_agent_id_input">Agent Id</Label>
        <TextInput
          id="rcs_agent_id_input"
          data-testid="rcs-agent-id-input"
          value={agentId}
          onChange={handleAgentIdChange}
          placeholder="soprano_demo_123gb5_agent"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="rcs_base_uri_input">Base Url</Label>
        <TextInput
          id="rcs_base_uri_input"
          data-testid="rcs-base-uri-input"
          value={baseUri}
          onChange={handleBaseUriChange}
          placeholder="https://uk.sopranodesign.com"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="rcs_id_input">Api Id</Label>
        <TextInput
          id="rcs_id_input"
          data-testid="rcs-id-input"
          value={apiId}
          onChange={handleApiIdChange}
          placeholder="Your api id"
        />
      </Flex>
      <Flex col mb>
        <Label htmlFor="rcs_key_input">Api Key</Label>
        <Flex between center>
          {showApiKey ? (
            <TextInput
              id="rcs_key_input"
              data-testid="rcs-key-input"
              value={apiKey}
              onChange={handleApiKeyChange}
              placeholder="Your api key"
            />
          ) : (
            <Span>Api key credentials have been set</Span>
          )}
          {!showApiKey && (
            <Flex top>
              <Button
                onClick={handleShowClientSecret}
                data-testid="change-rcs-key">
                Change Credentials
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex right mt>
        <Button
          variant="primary"
          onClick={handleSubmit}
          data-testid="submit-rcs-data">
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

RcsDataForm.propTypes = {
  agentId: PropTypes.string,
  token: PropTypes.string,
  noSecret: PropTypes.bool,
  prevBaseUri: PropTypes.string
};

export default RcsDataForm;
