import React, { useState, useEffect } from 'react';

import {
  Spinner,
  Panel,
  Label,
  TextInput,
  Button,
  Flex,
  useNotification,
  Heading3
} from '@ubisend/pulse-components';
import { TemplatingTextArea } from '@ubisend/pulse-templating';
import { useMutation, useQuery, useQueryClient } from '@ubisend/pulse-hooks';

import { updatePrompt, fetchPrompt } from '../api/index'; // Ensure these paths are correct

const Prompt = () => {
  const queryClient = useQueryClient();

  const { showSuccess, showError } = useNotification();
  const [promptName, setPromptName] = useState('');
  const [promptText, setPromptText] = useState('');

  const { data: promptResponse, isLoading } = useQuery(
    'generative-ai/prompt',
    fetchPrompt
  );
  const mutationPrompt = useMutation(updatePrompt);

  useEffect(() => {
    if (!isLoading && promptResponse) {
      setPromptName(promptResponse.data.name ?? '');
      setPromptText(promptResponse.data.custom_prompt ?? '');
    }
  }, [isLoading, promptResponse]);

  const handleSave = async event => {
    event.preventDefault();
    const prompt = {
      name: promptName,
      text: promptText
    };
    try {
      await mutationPrompt.mutateAsync(prompt);
      await queryClient.invalidateQueries('generative-ai/prompt');
      showSuccess('Successfully added a new prompt');
    } catch (error) {
      showError('Failed to update prompt: ' + error.message);
    }
  };

  return (
    <Flex col ySpace>
      <Heading3 mt mbSm>
        Prompt
      </Heading3>
      <Panel>
        <Flex col mt>
          <Label htmlFor="promptName">Prompt name</Label>
          {isLoading ? (
            <Spinner />
          ) : (
            <TextInput
              id="promptName"
              value={promptName}
              onChange={e => setPromptName(e.target.value)}
            />
          )}
        </Flex>
        <Flex col mt>
          <Label htmlFor="promptText">Prompt text</Label>
          {isLoading ? (
            <Spinner />
          ) : (
            <TemplatingTextArea
              id="promptText"
              value={promptText}
              onChange={setPromptText}
            />
          )}
        </Flex>
        <Flex right mt>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Flex>
      </Panel>
    </Flex>
  );
};

export default Prompt;
