import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { AnimatePresence } from '@ubisend/framer-motion';
import {
  PanelSlider,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableActions,
  Flex,
  Button,
  OrderableTableRow,
  useOrderableTableReducer,
  usePaginationReducer,
  Pagination
} from '@ubisend/pulse-components';
import { useQuery, useQueryClient } from '@ubisend/pulse-hooks';

import { recrawlSitemapUrl, fetchlSitemapUrl } from '../api/index';
import { StateLevel } from './index';

const SitemapUrlExplore = ({ sitemap: initialSitemap, handleHide }) => {
  const [loadingIds, setLoadingIds] = useState({});
  const [errorIds, setErrorIds] = useState({});

  const queryClient = useQueryClient();
  const order = useOrderableTableReducer({ id: 'sitemapUrls' });
  const pagination = usePaginationReducer({ id: 'sitemapUrls' });

  // Fetch sitemap URLs with order and pagination
  const { data: localSitemapUrls, error, isLoading } = useQuery([
    'generative-ai/sitemap/' + initialSitemap?.id + '/urls',
    { ...pagination.params, ...order.params }
  ]);

  // Return early if no sitemap
  if (!initialSitemap) {
    return null;
  }

  const handleRecrawl = async sitemap => {
    const { id } = sitemap;
    setLoadingIds(prev => ({ ...prev, [id]: true }));
    setErrorIds(prev => ({ ...prev, [id]: null }));
    sitemap.status = 'Pending';
    try {
      await recrawlSitemapUrl(id);
      queryClient.invalidateQueries(['sitemapUrls', initialSitemap.id]);
    } catch (err) {
      setErrorIds(prev => ({
        ...prev,
        [id]: 'Failed to recrawl the sitemap.'
      }));
    } finally {
      setLoadingIds(prev => ({ ...prev, [id]: false }));
    }
  };

  const handleFetchUrl = async url => {
    const urlId = url.id; // Assuming each URL has an 'id' property
    setLoadingIds(prev => ({ ...prev, [urlId]: true }));
    setErrorIds(prev => ({ ...prev, [urlId]: null }));
    try {
      const result = await fetchlSitemapUrl(urlId); // Adjust according to your API
      // Update the specific URL in localSitemapUrls
      const updatedData = localSitemapUrls.data.map(sitemapItem =>
        sitemapItem.id === urlId
          ? { ...sitemapItem, ...result.data.data } // Assuming result.data contains the fields to update
          : sitemapItem
      );

      // Update the query data with the modified localSitemapUrls
      queryClient.setQueryData(
        [
          'generative-ai/sitemap/' + initialSitemap?.id + '/urls',
          { ...pagination.params, ...order.params }
        ],
        {
          ...localSitemapUrls,
          data: updatedData
        }
      );
    } catch (err) {
      setErrorIds(prev => ({
        ...prev,
        [urlId]: 'Failed to fetch URL.'
      }));
    } finally {
      setLoadingIds(prev => ({ ...prev, [urlId]: false }));
    }
  };

  const meta = localSitemapUrls?.meta ?? {};

  return (
    <AnimatePresence>
      <PanelSlider header="Explore Sitemap Urls" handleHide={handleHide}>
        <Flex col>
          {isLoading ? (
            <p>Loading...</p>
          ) : error ? (
            <p style={{ color: 'red' }}>
              Failed to fetch sitemap URLs:{' '}
              {error.message || 'Unknown error occurred'}
            </p>
          ) : localSitemapUrls?.data?.length > 0 ? (
            <Flex border xScroll>
              <Table>
                <TableHead>
                  <OrderableTableRow
                    rows={[
                      { label: 'Url', sort: 'url' },
                      { label: 'Status', sort: 'status' },
                      { label: 'Crawled At', sort: 'updated_at' },
                      null
                    ]}
                    {...order.props}
                  />
                </TableHead>
                <TableBody>
                  {localSitemapUrls.data.map(sitemap => (
                    <TableRow key={sitemap.id}>
                      <TableCell>
                        <a
                          href={sitemap.url}
                          target="_blank"
                          rel="noopener noreferrer">
                          {sitemap.url}
                        </a>
                      </TableCell>
                      <TableCell>
                        <Flex center>
                          <StateLevel level={sitemap.status} />
                          <span>{sitemap.status}</span>
                        </Flex>
                      </TableCell>
                      <TableCell>
                        {dayjs(sitemap.updated_at).format('D MMM YYYY HH:mm')}
                      </TableCell>
                      <TableActions>
                        <Button
                          icon="download"
                          loading={loadingIds[sitemap.id]}
                          onClick={() => handleRecrawl(sitemap)}>
                          Re-Crawl
                        </Button>
                        <Button
                          icon="refresh"
                          loading={loadingIds[sitemap.id]}
                          onClick={() => handleFetchUrl(sitemap)}>
                          Refresh
                        </Button>
                        {errorIds[sitemap.id] && (
                          <p style={{ color: 'red' }}>{errorIds[sitemap.id]}</p>
                        )}
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Flex>
          ) : (
            <p>No URLs available for this sitemap.</p>
          )}
          {meta && <Pagination pagination={meta} {...pagination.props} />}
        </Flex>
      </PanelSlider>
    </AnimatePresence>
  );
};

SitemapUrlExplore.propTypes = {
  handleHide: PropTypes.func.isRequired,
  sitemap: PropTypes.object.isRequired
};

export default SitemapUrlExplore;
