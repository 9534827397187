import React from 'react';
import PropTypes from 'prop-types';

import { useQuery } from '@ubisend/pulse-hooks';
import { Select } from '@ubisend/pulse-components';

const format = ({ id, subject }) => ({ label: subject, value: id });

const EmailSelect = ({ value, currentlySelected, ...props }) => {
  const { data, isLoading, isSuccess } = useQuery('emails/all');

  const availableOptions = () => {
    return data.data.filter(
      item => !currentlySelected?.some(email => email.base.id === item.id) // eslint-disable-line
    );
  };

  return (
    <Select
      isLoading={isLoading}
      options={isSuccess ? availableOptions().map(format) : []}
      value={
        isSuccess &&
        value &&
        format(data.data.find(email => email.id === value))
      }
      {...props}
    />
  );
};

EmailSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentlySelected: PropTypes.object
};

export default EmailSelect;
